import { WithChildren } from '@kfz/check-ui/types';
import React, { useMemo } from 'react';

import { mergeClasses, getClasses } from 'modules/styles/utils';

import Spinner from '../Spinner/Spinner';

import { getStyles } from './styles.device';

enum ButtonType {
    PRIMARY,
    SECONDARY,
}

interface ButtonPropsInternal extends WithChildren, ButtonProps {
    type?: ButtonType;
}

type ButtonCallback = () => void | Promise<void>;

interface ButtonProps extends WithChildren {
    action: string | ButtonCallback;
    className?: string;
    tabIndex?: number;
    disabled?: boolean;
    loading?: boolean;
    spinnerOnLoading?: boolean;
    spinnerColor?: string;
    id?: string;
    target?: '_blank';
}

const Button = ({
    action,
    children,
    className = '',
    tabIndex = undefined,
    disabled = false,
    loading = false,
    spinnerOnLoading = false,
    spinnerColor,
    type = ButtonType.PRIMARY,
    id = undefined,
    target = undefined,
}: ButtonPropsInternal) => {
    const styles = getStyles(type === ButtonType.PRIMARY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cssClasses = useMemo(() => getClasses<typeof styles>(styles), [type]);
    const buttonClass = mergeClasses(cssClasses.button, className);
    const actionType = typeof action;
    const isLink = actionType === 'string';
    const actionIsCallback = actionType === 'function';
    if (!isLink && !actionIsCallback) {
        throw new Error(`Expected action to be either a string or a function, received ${actionType}`);
    }

    if (isLink) {
        const rel = target === '_blank' ? 'noopener noreferrer' : undefined;
        return (
            <a className={buttonClass} href={String(action)} tabIndex={tabIndex} id={id} target={target} rel={rel}>
                {children}
            </a>
        );
    }
    const onClick = action as ButtonCallback;
    return (
        <button className={buttonClass} type="button" onClick={onClick} tabIndex={tabIndex} disabled={disabled} id={id}>
            {children}
            {loading && spinnerOnLoading && <Spinner color={spinnerColor} />}
        </button>
    );
};

export const PrimaryButton = (props: ButtonProps) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Button type={ButtonType.PRIMARY} {...props} />;
};

export const SecondaryButton = (props: ButtonProps) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Button type={ButtonType.SECONDARY} spinnerColor="#666" {...props} />;
};
