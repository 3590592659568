import constants from 'modules/styles/constants';

import { getErrorStyles } from '../styles/errorStyles';

export const styles = {
    text: {
        color: constants.TEXT_DARK,
        fontSize: 14,
        marginBottom: 25,
    },
    infoListWrapper: {
        marginTop: 30,
    },
    infoListHeadline: {
        fontWeight: 'bold',
        marginBottom: 10,
    },
    error: getErrorStyles(),
} as const;
