import { WithChildren } from '@kfz/check-ui/types';
import React from 'react';

import { getClasses } from 'modules/styles/utils';

import { styles } from './styles.device';

const cssClasses = getClasses<typeof styles>(styles);

type Props = WithChildren;

export default ({ children }: Props) => <h2 className={cssClasses.headline}>{children}</h2>;
