import constants from 'modules/styles/constants';

export const getStyles = (isPrimary = true) => {
    const buttonBgColor = isPrimary ? constants.BUTTON_COLOR : '#fafafa';
    const buttonBgColorHover = isPrimary ? constants.BUTTON_COLOR_HOVER : '#f2f2f2';
    const buttonOnHoverTextColor = isPrimary ? constants.WHITE : constants.TEXT_MEDIUM;
    const textColor = isPrimary ? constants.WHITE : constants.TEXT_MEDIUM;
    const border = isPrimary ? 'none' : `1px solid #B4b4b4`;
    return {
        button: {
            cursor: 'pointer',
            borderRadius: 3,
            background: buttonBgColor,
            color: textColor,
            textAlign: 'center',
            textDecoration: constants.NONE,
            border,
            fontWeight: 'bold',
            fontSize: 14,
            height: 40,
            padding: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Arial,Helvetica,sans-serif',
            '&:focus': {
                outline: `1px dotted ${constants.TEXT_LIGHT}`,
            },
            '&:hover': {
                textDecoration: `${constants.NONE}`,
                background: `${buttonBgColorHover}`,
                color: buttonOnHoverTextColor,
            },
        },
    } as const;
};
