export const styles = {
    appLink: {
        display: 'block',
        width: '300px',
        padding: '20px',
        background: 'rgb(2, 113, 194)',
        color: '#fff',
        textAlign: 'center',
        margin: '0px auto',
        textDecoration: 'none',
        boxSizing: 'border-box',
        boxShadow: 'rgba(0, 0, 0, 0.26) 0px 2px 5px',
        fontFamily: 'Arial, sans-serif',

        borderRadius: '5px',
        '&:active': {
            color: '#fff',
        },
    },
} as const;
