import { hexToRgb } from '@kfz/check-ui/colors';

import { spinAround } from 'modules/styles/keyframes';

export const getStyles = (
    width: number,
    height: number,
    borderWidth: number,
    color: string,
    left: number,
    top: number | undefined
) => {
    const rgbColor = hexToRgb(color);

    return {
        wrapper: {
            display: 'inline-block',
            position: 'relative',
            width: 0,
            height: 0,
            verticalAlign: 'top',
        },

        inner: {
            width,
            height,
            position: 'absolute',
            left,
            top: top ?? -(height / 2),

            '&:before': {
                content: "''",
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                opacity: '0.8',
            },

            '&:after': {
                content: "''",
                display: 'block',
                top: 0,
                left: 0,
                width: width * 0.75,
                height: height * 0.75,
                border: `${borderWidth}px solid rgba(0, 0, 0, 0.3)`,
                borderRadius: '50%',
                animation: `${spinAround} 700ms infinite linear`,
                borderColor: `${color} rgba(${rgbColor}, 0.3) rgba(${rgbColor}, 0.3) rgba(${rgbColor}, 0.3)`,
            },
        },
    } as const;
};
