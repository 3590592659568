import { ListOption } from '@kfz/check-ui/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { makeKey } from '@oz/shared/out/modules/object/makeKey';
import { ObjectSearchPath } from '@oz/shared/out/types/ObjectSearchPath';

type DynamicOptions = Record<string, ListOption[]>;

// Slice
const slice = createSlice({
    name: 'dynamicOptions',
    initialState: {},
    reducers: create => ({
        clearAllOptions: create.reducer((): DynamicOptions => {
            return {};
        }),
        initialize: create.reducer((state: DynamicOptions, action: PayloadAction<DynamicOptions>): DynamicOptions => {
            return action.payload;
        }),
        setOptions: create.reducer(
            (
                state: DynamicOptions,
                action: PayloadAction<{ path: ObjectSearchPath; options: ListOption[] }>
            ): DynamicOptions => {
                const { path, options } = action.payload;
                const key = makeKey(path);
                if (options) {
                    // eslint-disable-next-line no-param-reassign
                    state[key] = options;
                } else {
                    // eslint-disable-next-line no-param-reassign
                    delete state[key];
                }
                return state;
            }
        ),
    }),
});
export default slice.reducer;

// Actions
export const { setOptions, initialize } = slice.actions;
