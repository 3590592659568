// eslint-disable-next-line no-restricted-imports
import { getArrowLeft } from '@kfz/check-ui/utils';

import constants from 'modules/styles/constants';

export const styles = {
    rowControl: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        flexWrap: 'wrap',
    },
    controlWrapper: {
        flex: 1,
    },
    helpWrapper: {
        width: 36,
        flexShrink: 0,
    },
    wrapper: {
        position: 'relative',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 13,
    },
    helptext: {
        position: 'absolute',
        boxSizing: 'border-box',
        width: 200,
        padding: '0px 20px',
        top: 0,
        right: -250,
        hyphens: 'auto',
    },
    helptextContainer: {
        position: 'relative',
        top: 0,

        h1: { fontSize: 12 },
        p: { fontSize: 12 },
    },

    arrowWrapper: {
        position: 'absolute',
        left: 0,
    },
    arrowCorner: {
        display: 'block',
        ...getArrowLeft(14, 14, 1, '#fafafa', true),
    },
    modalHelpContent: {
        padding: 25,
        fontSize: 14,
        color: constants.TEXT_DARK,
        fontFamily: 'verdana',
        h3: {
            fontWeight: 'bold',
            padding: '0 0 3px 0',
            margin: '0 0 7px 0',
        },
        p: {
            paddingBottom: 12,
            margin: '0 0 7px 0',
        },
        img: {
            maxWidth: '100%',
        },
    },
} as const;
