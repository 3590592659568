// Slice
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DatenModell } from '@oz/shared/out/interfaces/DatenModell';
import { OzDataStatus } from '@oz/shared/out/interfaces/OzDataStatusContainer';
import { SaveDataResponse } from '@oz/shared/out/interfaces/SaveDataResponse';
import { setIn } from '@oz/shared/out/modules/object/object';
import { ObjectSearchPath } from '@oz/shared/out/types/ObjectSearchPath';

import { setReinitialize } from '../sideEffects/onStoreChange';

export type NullableDatenModell = DatenModell | null;

const slice = createSlice({
    name: 'datenModell',
    initialState: null as NullableDatenModell,
    reducers: create => ({
        initializeDatenModell: create.reducer(
            (state: NullableDatenModell, action: PayloadAction<DatenModell>): NullableDatenModell => {
                setReinitialize();
                return action.payload;
            }
        ),
        setDatenModell: create.reducer(
            (state: NullableDatenModell, action: PayloadAction<DatenModell>): NullableDatenModell => {
                return action.payload;
            }
        ),
        setValue: create.reducer(
            <T>(
                state: NullableDatenModell,
                action: PayloadAction<{ path: ObjectSearchPath; value: T }>
            ): NullableDatenModell => {
                const setPath = [...action.payload.path];

                return setIn(state, setPath, action.payload.value) as NullableDatenModell;
            }
        ),
        setValues: create.reducer(
            <T>(state: NullableDatenModell, action: PayloadAction<{ path: ObjectSearchPath; value: T }[]>): void => {
                action.payload.forEach(payload => {
                    const setPath = [...payload.path];
                    setIn(state, setPath, payload.value);
                });
            }
        ),
        setDataStatus: create.reducer((state: NullableDatenModell, action: PayloadAction<OzDataStatus[]>): void => {
            if (state != null) {
                const datenmodell: DatenModell = state as DatenModell;
                // eslint-disable-next-line no-param-reassign
                datenmodell.metaDaten.status.states = action.payload;
            }
        }),
        mergeToDataMetaData: create.reducer(
            (state: NullableDatenModell, action: PayloadAction<SaveDataResponse['data']>): void => {
                if (state != null) {
                    const datenmodell: DatenModell = state as DatenModell;
                    // eslint-disable-next-line no-param-reassign
                    datenmodell.metaDaten = { ...datenmodell.metaDaten, ...action.payload };
                }
            }
        ),
    }),
});
export default slice.reducer;

// Actions
export const { mergeToDataMetaData, setDatenModell, initializeDatenModell, setValue, setDataStatus } = slice.actions;
