/*
 * This container is necessary to save the tracking data in a separate array, in order to access them later for testing or debugging purposes.
 * Matomos internal _paq object is an array only at first iteration, after the Matomo script is loaded, it is transformed to a custom object,
 * that does  not expose its internal data.
 * */

export default () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-multi-assign,no-underscore-dangle
    const container = (window._paq = window._paq || []);
    const containerDebugInfo = [];
    return {
        push(config: (string | number)[]): void {
            // config must be cloned, matomo's internal workings mutate the configs
            // @ts-ignore
            containerDebugInfo.push([...config]);
            container.push(config);
        },
        getCurrentContainer(): (string | number)[][] {
            return containerDebugInfo;
        },
    };
};
