import { isMobile } from 'modules/applicationConfig';
import { injectGlobal } from 'modules/styles/utils';

import { CONSOLAS_FONT_PATH, KENNZEICHEN_FONT_PATH, OCRB_FONT_PATH } from '../../constants/staticFiles';

import constants from './constants';

export const injectGlobalStyles = (): void => {
    // eslint-disable-next-line no-unused-expressions
    injectGlobal`
        div, span, object, iframe,
        h1, h2, h3, h4, h5, h6, p, blockquote, pre,
        a, abbr, acronym, address, big, cite, code,
        del, dfn, img, ins, kbd, q, s, samp,
        small, strike, sub, sup, tt, var,
        b, u, i, center,
        dl, dt, dd, ol, ul, li,
        fieldset, form, label, legend,
        table, caption, tbody, tfoot, thead, tr, th, td,
        article, aside, canvas, details, embed,
        figure, figcaption, footer, header, hgroup,
        menu, nav, output, ruby, section, summary,
        time, mark, audio, video {
            margin: 0;
            padding: 0;
            border: 0;
            font: inherit;
            font-size: 100%;
            vertical-align: baseline;
        }

        @font-face {
          font-family: 'EuroPlate';
          src: url('${KENNZEICHEN_FONT_PATH}') format('truetype');
        }

        @font-face {
          font-family: 'Consolas';
          src: url('${CONSOLAS_FONT_PATH}') format('opentype');
        }

        @font-face {
          font-family: 'ocrb';
          src: url('${OCRB_FONT_PATH}') format('opentype');
        }
        
        /* disable flicker on iPad when event delegation is used*/
        * {
            -webkit-tap-highlight-color: transparent;
        }
        
        * :focus {
            outline: 1px dotted #b4b4b4
        }
        
        aside:focus {
            outline: none;
        }
        
        main:focus {
            outline: none;
        }
        
        html {
            scroll-behavior: initial;
        }
        
        body {
            margin: 0;
            padding: 0;
            background-color: ${constants.MAIN_CONTENT_BACKGROUND_COLOR};
            font-family: ${isMobile() ? 'Arial, sans-serif' : 'Verdana, sans-serif'};
        }
        
        main {
            background-color: ${constants.MAIN_CONTENT_BACKGROUND_COLOR} !important;
        }
        
        b,
        strong {
            font-weight: bold;
        }
        
        ol, ul {
            list-style: none;
        }
        
        table {
            border-collapse: collapse;
            border-spacing: 0;
        }
        
        a {
            color: ${constants.LINK_COLOR};
            text-decoration: ${constants.LINK_DECORATION};
        }
                    
        a:hover {
            color: ${constants.LINK_HOVER_COLOR};
            text-decoration: ${constants.LINK_DECORATION};
        }
        
        input {
            border-radius: 0;
        }
        
        @keyframes shake {
          10%, 90% {
            transform: translate3d(-1px, 0, 0);
          }
          
          20%, 80% {
            transform: translate3d(2px, 0, 0);
          }
        
          30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0);
          }
        
          40%, 60% {
            transform: translate3d(4px, 0, 0);
          }
        }
         #c24_support_wrapper {
            visibility: visible!important;
         }
        #c24_support_wrapper  .c24-phone {
            margin-top: -2px;
        }
        #c24_support_wrapper #c24_service_code {
            
            font-size: 11px;
            
        }
        `;
};
