import constants from 'modules/styles/constants';

export const styles = {
    wrapper: {
        fontSize: 12,
        marginBottom: 20,
    },
    row: {
        display: 'flex',
        color: constants.TEXT_MEDIUM,
        marginBottom: 5,
    },
    icon: {
        fill: constants.VALID_COLOR,
        width: 15,
        height: 15,
        marginRight: 5,
    },
    text: {},
    rowNumberTheme: {
        marginBottom: 10,
        display: 'flex',
    },
    textNumberTheme: {
        fontSize: 14,
        color: constants.TEXT_MEDIUM,
    },
    iconNumberTheme: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        background: constants.COLOR_PRIMARY_DARK,
        color: constants.WHITE,
        marginRight: 7,
        textAlign: 'center',
        lineHeight: '19px',
    },
    ctaWrapper: {
        display: 'flex',
    },
    cta: {},
} as const;
