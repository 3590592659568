import { Styles } from '@kfz/check-ui/types';
// eslint-disable-next-line no-restricted-imports
import { css } from '@kfz/check-ui/utils';

type ClassNameMap<Type> = {
    [Property in keyof Type]: string;
};

// eslint-disable-next-line no-restricted-imports
export { cx, keyframes, injectGlobal } from '@kfz/check-ui/utils';

export function getClasses<T = undefined>(styles: Styles): T extends undefined ? Record<string, string> : ClassNameMap<T> {
    const classes = {};
    Object.entries(styles).forEach(([key, val]) => {
        if (typeof val === 'object') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            classes[key] = css(val);
        }
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return classes;
}

export const mergeClasses = (first: string, second: string): string => css(first, second);

const IOS_AGENT_REGEX = /iPad|iPhone|iPod/;
export const getSafeBottomOfScreen = (isApp: boolean): number => {
    return IOS_AGENT_REGEX.test(navigator.userAgent) && isApp ? 70 : 0;
};
