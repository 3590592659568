import { WithChildren } from '@kfz/check-ui/types';
import React from 'react';

import { getClasses } from 'modules/styles/utils';

import { styles } from './styles.device';

const cssClasses = getClasses<typeof styles>(styles);

interface SectionHeadlineProps extends WithChildren {
    styleType?: 'top_of_section' | 'within_section';
}

export default ({ children, styleType = 'top_of_section' }: SectionHeadlineProps) => {
    const cssStyles = styleType === 'within_section' ? { paddingTop: 25 } : undefined;
    return (
        <div className={cssClasses.headline} style={cssStyles}>
            {children}
        </div>
    );
};
