import { WithChildren } from '@kfz/check-ui/types';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationError } from '@oz/shared/out/enums/ApplicationError';
import { globalErrorLog } from 'modules/logs/logHelper';
import { clearApplicationError } from 'store/slices/applicationErrorSlice';

import { ApplicationState } from '../../../interfaces/ApplicationState';

import ErrorModal from './ErrorModal';

const getApplicationError = (state: ApplicationState) => state.applicationError;

type Props = WithChildren;

export default ({ children }: Props) => {
    const appError = useSelector(getApplicationError);
    const [currentAppError, setCurrentAppError] = useState(appError);
    const dispatch = useDispatch();
    globalErrorLog();

    useEffect(() => {
        if (appError != null) {
            setCurrentAppError(appError);
            dispatch(clearApplicationError());
        }
    }, [appError, dispatch]);

    const getErrorModal = useCallback(error => {
        return <ErrorModal error={error} errorType={ApplicationError.RENDER} />;
    }, []);

    return (
        <ErrorBoundary
            FallbackComponent={({ error }) => getErrorModal(error)}
            onReset={() => {
                window.location.reload();
            }}
        >
            {currentAppError === undefined || currentAppError === null ? (
                <div>{children}</div>
            ) : (
                <ErrorModal error={currentAppError.errorMessage} errorType={currentAppError.error} />
            )}
        </ErrorBoundary>
    );
};
