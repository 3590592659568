import { isMobile } from 'modules/applicationConfig';

import { CssConstants } from '../../interfaces/CssConstants';

const GAINSBORO = '#DCDCDC';
const GRAY44 = '#707070';
const GREY80 = '#ccc';
const GREY_VERY_LIGHT = '#c8c8c8';
const GREY_LIGHTER = '#aaa';
const GREY40 = '#666';
const GREY34 = '#575757';
const GREY20 = '#333';
const GREY60 = '#999';
const GREEN = '#79b51d';
const SALMON = '#FF6C68';
const BURGUNDY = '#c82d2d';
const ALMOST_WHITE = '#f4f4f4';
const LIMA = '#7ab51d';

const NONE = 'none';

const HIDE_TITLE = {
    text: '',
    show: () => false,
};
const CHECK_BLUE = '#005ea8';
const CHECK_BLUE_DARK = '#063773';
const CHECK_BLUE_LIGHT = '#ECF7FD';
const CHECK_NEW_ORANGE = '#C05702';
const CHECK_BUTTON_BLUE = '#0271C2';
const CHECK_BUTTON_BLUE_HOVER = '#015FA4';
const CHECK_SELECTION_COLOR = '#0089ec';
const CHECK_CALENDAR_HOVER = '#b1dcfb';
const GREY98 = '#fafafa';

const themeCheckvers = {
    ACTIVE_STATUS_COLOR: CHECK_BLUE,
    ACTIVE_STATUS_BORDER: CHECK_BLUE,
    ACTIVE_CONTROL_COLOR: CHECK_BLUE,
    ACTIVE_HELP_COLOR: CHECK_BLUE,
    BUTTON_COLOR: CHECK_BUTTON_BLUE,
    BUTTON_COLOR_HOVER: CHECK_BUTTON_BLUE_HOVER,
    SELECTION_COLOR: CHECK_SELECTION_COLOR,
    CALENDAR_HOVER: CHECK_CALENDAR_HOVER,
    LINK_COLOR: CHECK_BLUE,
    LINK_HOVER_COLOR: isMobile() ? CHECK_BLUE : CHECK_NEW_ORANGE,
    BACK_LINK_COLOR: CHECK_BLUE,
    COLOR_PRIMARY: CHECK_BLUE,
    COLOR_PRIMARY_DARK: CHECK_BLUE_DARK,
    COLOR_PRIMARY_LIGHT: CHECK_BLUE_LIGHT,
    TIP_BORDER_COLOR: '#b4b4b4',
    HEADLINE_COLOR: GREY34,
    DESKTOP_CONTENT_WIDTH: 994,
    HELPTEXT_WIDTH_DESKTOP: '28%',
    MAIN_CONTENT_BACKGROUND_COLOR: isMobile() ? ALMOST_WHITE : GREY98,
    TITLE: 'Online-Zulassung',
    WARNING_ORANGE: CHECK_NEW_ORANGE,
} as CssConstants;

const constants = <CssConstants>{
    // COLORS
    NONE,
    WHITE: '#fff',
    TITLE: HIDE_TITLE,
    MOBILE_INPUT_TEXT_COLOR: GRAY44,
    COLOR_PRIMARY: GREY34,
    COLOR_PRIMARY_DARK: GREY20,
    COLOR_PRIMARY_LIGHT: GAINSBORO,
    TIP_BORDER_COLOR: GREY34,
    ACTIVE_STATUS_COLOR: GREY34,
    ACTIVE_CONTROL_COLOR: GREY34,
    BUTTON_COLOR: GREY34,
    REGULAR_BORDER: GREY80,
    LIGHT_BORDER: GREY_VERY_LIGHT,
    SECONDARY_BUTTON_COLOR: ALMOST_WHITE,
    LINK_COLOR: GREY34,
    LINK_HOVER_COLOR: GREY34,
    BACK_LINK_COLOR: GREY34,
    HEADLINE_COLOR: GREY34,
    HEADLINE_COLOR_MOBILE: GREY40,
    SELECTION_COLOR: GREY34,
    CALENDAR_HOVER: GREY_LIGHTER,
    ABORT_BUTTON_FONT: GREY40,
    TEXT_AFTER_INPUT: GREY40,
    MAIN_CONTENT_BACKGROUND_COLOR: ALMOST_WHITE,
    INPUT_COLOR: GREY20,
    BORDER_COLOR: GAINSBORO,
    TEXT_DARK: GREY20,
    TEXT_MEDIUM: GREY40,
    TEXT_LIGHT: GREY60,
    LINK_DECORATION: 'none',
    DESKTOP_CONTENT_WIDTH: '100%',
    OPX_CONTENT_WIDTH: 750,
    HELPTEXT_WIDTH_DESKTOP: '22%',
    BUTTON_COLOR_HOVER: GREY20,
    LAYOUT_MAX_WIDTH: '100%',
    LAYOUT_MARGIN: 0,
    LAYOUT_ALIGN_ITEMS: 'center',
    CHECKMARK_COLOR: GREEN,
    VOUCHER_COLOR: '#CCE7A0',
    ERROR: BURGUNDY,
    ACTIVE_STATUS_BORDER: GREY60,
    RED_PRICE: BURGUNDY,
    COLOR_ACTION: SALMON,
    INPUT_BORDER: GAINSBORO,
    INPUT_BORDER_HOVER: GREY20,
    VALID_COLOR: LIMA,
};
export default { ...constants, ...themeCheckvers };
