import { FormProvider } from '@kfz/check-ui/context/FormContext';
import { WithChildren } from '@kfz/check-ui/types';
import React, { JSX } from 'react';

import { getClasses } from 'modules/styles/utils';

import useScrollToTopOnMount from '../../../hooks/useScrollToTopOnMount';
import { PrimaryButton, SecondaryButton } from '../Button/Button';

import { styles } from './styles.device';

const cssClasses = getClasses<typeof styles>(styles);

interface OneColLayoutProps extends WithChildren {
    buttonText: string;
    buttonAction: () => void;
    loading: boolean;
    abortAction?: () => void;
    belowButton?: () => JSX.Element;
}

export default ({ children, buttonAction, buttonText, loading, abortAction, belowButton }: OneColLayoutProps) => {
    useScrollToTopOnMount();

    const BelowButton = belowButton;

    return (
        <FormProvider>
            <div className={cssClasses.wrapper}>
                {children}
                <div className={cssClasses.buttonWrapper}>
                    {abortAction && (
                        <SecondaryButton className={cssClasses.secondaryButton} action={abortAction}>
                            abbrechen
                        </SecondaryButton>
                    )}
                    <PrimaryButton action={buttonAction} className={cssClasses.button} spinnerOnLoading loading={loading}>
                        {buttonText}
                    </PrimaryButton>
                </div>
                {BelowButton && <BelowButton />}
            </div>
        </FormProvider>
    );
};
