import React from 'react';

import { openLoginLayer } from 'modules/loginLayer';
import { getClasses } from 'modules/styles/utils';
import { isRunningInApp } from 'modules/utils/detection';

import { PrimaryButton } from '../Button/Button';

import { styles } from './styles';

const cssClasses = getClasses<typeof styles>(styles);

interface LoginButtonProps {
    className: string;
    action?: () => void;
}

const getCurrentUrlWithoutProtocol = () => {
    const curr = window.location.href;
    return curr.substring(curr.indexOf('//') + 2);
};
const NOOP = () => {};
export default ({ className, action = NOOP }: LoginButtonProps) => {
    return isRunningInApp() ? (
        <a
            href={`c24-app-login://${getCurrentUrlWithoutProtocol()}?allowRelogin=true`}
            className={`${cssClasses.appLink} ${className}`}
        >
            jetzt anmelden
        </a>
    ) : (
        <PrimaryButton
            action={() => {
                action();
                setTimeout(() => {
                    openLoginLayer();
                }, 200);
            }}
            className={className}
        >
            jetzt anmelden
        </PrimaryButton>
    );
};
