import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';

import { OzRoute } from '../../../enums/OzRoute';
import useInitialRoute from '../../../hooks/useInitialRoute';
import useRouteWithSession from '../../../hooks/useRouteWithSession';

const Dateneingabe = lazy(() => import('../Dateneingabe/Dateneingabe.device'));
const Hub = lazy(() => import('../Hub/Hub'));
const Uebersicht = lazy(() => import('../Uebersicht/Uebersicht'));
const BereitsZugelassen = lazy(() => import('../BereitsZugelassen/BereitsZugelassen'));
const FahrzeugdatenKorrigieren = lazy(() => import('../FahrzeugdatenKorrigieren/FahrzeugdatenKorrigieren'));
const BankdatenKorrigieren = lazy(() => import('../BankdatenKorrigieren/BankdatenKorrigieren'));

const AppRouter = () => {
    const initialRoute = useInitialRoute();
    const defaultRouteWithSession = useRouteWithSession(initialRoute);
    return (
        <Suspense fallback={null}>
            <RouterRoutes>
                <Route path={OzRoute.DATENEINGABE} element={<Dateneingabe />} />
                <Route path={OzRoute.UEBERSICHT} element={<Uebersicht />} />
                <Route path={OzRoute.HUB} element={<Hub />} />
                <Route path={OzRoute.BEREITS_ZUGELASSEN} element={<BereitsZugelassen />} />
                <Route path={OzRoute.BANKDATEN_KORRIGIEREN} element={<BankdatenKorrigieren />} />
                <Route path={OzRoute.FAHRZEUGDATEN_KORRIGIEREN} element={<FahrzeugdatenKorrigieren />} />

                <Route path="/" element={<Navigate replace to={defaultRouteWithSession} />} />
            </RouterRoutes>
        </Suspense>
    );
};

export default AppRouter;
