import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FieldError } from '@oz/shared/out/interfaces/FieldError';
import { makeKey } from '@oz/shared/out/modules/object/makeKey';
import { ObjectSearchPath } from '@oz/shared/out/types/ObjectSearchPath';
import { SectionValidation } from '@oz/shared/out/types/SectionValidation';
import { ValidationResult } from '@oz/shared/out/types/ValidationResult';

type FieldErrors = Record<string, FieldError> | null;
const mapValidationResultToErrors = (validationResult: ValidationResult, state: FieldErrors = {}): FieldErrors => {
    const newState = { ...state };
    if (validationResult !== null) {
        Object.values(validationResult).forEach(sectionValidation => {
            (sectionValidation as SectionValidation).forEach(([path, fieldValidation]) => {
                newState[makeKey(path)] = fieldValidation;
            });
        });
    }

    return newState;
};

// Slice
const slice = createSlice({
    name: 'fieldErrors',
    initialState: null as FieldErrors,
    reducers: create => ({
        clearAllErrors: create.reducer((): FieldErrors => {
            return {};
        }),
        // @ts-ignore
        resetErrors: create.reducer((state: FieldErrors, action: PayloadAction<ValidationResult>): FieldErrors => {
            return mapValidationResultToErrors(action.payload);
        }),
        setErrors: create.reducer((state, action: PayloadAction<SectionValidation>) => {
            const validation = action.payload;
            validation.forEach(([path, fieldValidation]) => {
                if (state != null) {
                    // eslint-disable-next-line no-param-reassign
                    state[makeKey(path)] = fieldValidation;
                }
            });
        }),
        setError: create.reducer(
            (
                state: FieldErrors,
                action: PayloadAction<{ path: ObjectSearchPath; fieldValidation: FieldError | undefined }>
            ): FieldErrors => {
                const { path, fieldValidation } = action.payload;
                const key = makeKey(path);
                if (state != null) {
                    if (fieldValidation) {
                        // eslint-disable-next-line no-param-reassign
                        state[key] = fieldValidation;
                    } else {
                        // eslint-disable-next-line no-param-reassign
                        delete state[key];
                    }
                }
                return state;
            }
        ),
        deleteErrorsInList: create.reducer(
            (state: FieldErrors, action: PayloadAction<{ basePath: ObjectSearchPath; deleteIndex: number }>): void => {
                const { basePath, deleteIndex } = action.payload;
                const baseKey = makeKey(basePath);

                const resetDriversErrors: SectionValidation = [];
                if (state != null) {
                    Object.entries(state).forEach(([key, value]) => {
                        if (key.startsWith(baseKey)) {
                            const path = key.split('_') as ObjectSearchPath;
                            const positionOfIndexInPath = path.length - 2; // index is the second last. This is a heuristic, might not work for other data
                            const index = parseInt(String(path[positionOfIndexInPath]), 10);
                            if (index < deleteIndex) {
                                resetDriversErrors.push([path, value]);
                            } else if (index > deleteIndex) {
                                path[positionOfIndexInPath] = index - 1;
                                resetDriversErrors.push([path, value]);
                            }

                            // eslint-disable-next-line no-param-reassign
                            delete state[key];
                        }
                    });
                    resetDriversErrors.forEach(([path, fieldValidation]) => {
                        // eslint-disable-next-line no-param-reassign
                        state[makeKey(path)] = fieldValidation;
                    });
                }
            }
        ),
    }),
});
export default slice.reducer;

// Actions
export const { clearAllErrors, resetErrors, setError, deleteErrorsInList, setErrors } = slice.actions;
