import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApplicationError } from '@oz/shared/out/enums/ApplicationError';

import { ApplicationErrorState } from '../../interfaces/ApplicationErrorState';

type NullableApplicationErrorState = ApplicationErrorState | null;
const getErrorState = (message: string): ApplicationErrorState => {
    const applicationError = ApplicationError[message];
    if (applicationError) {
        return {
            error: applicationError,
            errorMessage: undefined,
        };
    }

    return {
        error: ApplicationError.UNKNOWN,
        errorMessage: message,
    };
};

// Slice
const slice = createSlice({
    name: 'applicationError',
    initialState: null as NullableApplicationErrorState,
    reducers: create => ({
        setApplicationError: create.reducer((state: NullableApplicationErrorState, action: PayloadAction<string>) => {
            return getErrorState(action.payload);
        }),

        clearApplicationError: create.reducer((): NullableApplicationErrorState => {
            // @ts-ignore
            return null;
        }),
    }),
});
export default slice.reducer;

// Actions
export const { setApplicationError, clearApplicationError } = slice.actions;
