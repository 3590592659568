import constants from 'modules/styles/constants';

export const styles = {
    headline: {
        color: constants.HEADLINE_COLOR,
        marginBottom: 25,
        fontSize: 20,
        fontWeight: 400,
        fontFamily: 'Verdana',
        lineHeight: '22px',
    },
};
