import debounce from 'lodash/debounce';
import { useEffect } from 'react';
import { Location, useLocation } from 'react-router-dom';

import { Tracking } from '@oz/shared/out/interfaces/Tracking';
import { dispatchGeneralTracking } from 'modules/analytics/generaltracking/generalTracking';
import { dispatchPageTracking } from 'modules/analytics/matomo/dispatchPageTracking';
import { getTrackingData, isMatomoEnabled } from 'modules/applicationConfig';

const locationChangeCallback = (location: Location, trackingModell: Tracking) => {
    dispatchGeneralTracking(location);

    if (isMatomoEnabled()) {
        dispatchPageTracking(trackingModell, location);
    }
};

const debouncedCallback = debounce(locationChangeCallback, 750);

export const useLocationListener = (): void => {
    const location = useLocation();
    const trackingModell = getTrackingData();

    useEffect(() => {
        debouncedCallback(location, trackingModell);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, location.search, trackingModell]);
};
