import { WithChildren } from '@kfz/check-ui/types';
import React, { useEffect, useRef, useState } from 'react';

import { getClasses } from 'modules/styles/utils';

import useIsInlineHelp from '../../../hooks/useIsInlineHelp';

import { styles } from './styles.device';
import { resetRootElem } from './utils/position';

const cssClasses = getClasses<typeof styles>(styles);

interface HelpTextProps extends WithChildren {
    testId?: string;
}

const useHelpTextPosition = (helpTextRef, helpTextNoseRef) => {
    // const { sectionRef } = useContext(SectionContext); // TODO secton context
    // const sectionHeight = getHeightFromRef(sectionRef);
    const [initialCalculationDone, setInitialCalculationDone] = useState(false);

    useEffect(() => {
        const checkHepPosition = () => {
            // setHelpPos(helpTextRef, helpTextNoseRef, sectionRef);
        };

        setInitialCalculationDone(false);
        const timeout = setTimeout(() => {
            // setHelpPos(helpTextRef, helpTextNoseRef, sectionRef);
            window.addEventListener('resize', checkHepPosition);
            setInitialCalculationDone(true);
        }, 50);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            window.removeEventListener('resize', checkHepPosition);
            resetRootElem();
        };
    }, [helpTextNoseRef, helpTextRef]);

    return initialCalculationDone;
};

// on close is use in mobile / non inline helptext
const HelpText = ({ children, testId }: HelpTextProps) => {
    const inline = useIsInlineHelp();
    const helpTextRef = useRef(null);
    const helpTextNoseRef = useRef(null);

    const initialCalculationDone = useHelpTextPosition(helpTextRef, helpTextNoseRef);

    const visibility = inline || initialCalculationDone ? 'visible' : 'hidden';

    return (
        <div className={cssClasses.outer} style={{ visibility }} ref={helpTextRef} data-test={testId}>
            <div className={cssClasses.content}>
                <span className={cssClasses.corner} ref={helpTextNoseRef} />
                {children}
            </div>
        </div>
    );
};

export default HelpText;
