import { Tracking } from '@oz/shared/out/interfaces/Tracking';
import { getDevice } from 'modules/applicationConfig';
import { writeToLogConsole } from 'modules/tamperMonkeyAdapter';

import { BASE_URL, PAGE_SCOPE, SET_CUSTOM_VAR_KEY, VISIT_SCOPE } from './constants';
import getMatomoData from './getMatomoData';
import PageTrackingContrainer from './PageTrackingContrainer';

export default (trackingModell: Tracking, pagename: string): void => {
    const matomoTrackingContainer = PageTrackingContrainer();
    const { cpid, matomoSiteId, cpref, trackingId } = getMatomoData(trackingModell);
    const deviceoutput = getDevice();

    // Basic stuff
    matomoTrackingContainer.push(['setDocumentTitle', pagename]);

    matomoTrackingContainer.push(['setCustomUrl', document.URL]);

    // custom vars
    matomoTrackingContainer.push([SET_CUSTOM_VAR_KEY, 1, 'pagename', pagename, PAGE_SCOPE]);

    matomoTrackingContainer.push([SET_CUSTOM_VAR_KEY, 2, 'cpref', cpref, VISIT_SCOPE]);
    matomoTrackingContainer.push([SET_CUSTOM_VAR_KEY, 4, 'wp', cpid, VISIT_SCOPE]);
    matomoTrackingContainer.push([SET_CUSTOM_VAR_KEY, 5, 'device_output', deviceoutput, VISIT_SCOPE]);
    matomoTrackingContainer.push([SET_CUSTOM_VAR_KEY, 6, 'session_id', trackingId, VISIT_SCOPE]);

    matomoTrackingContainer.push(['enableLinkTracking']);
    matomoTrackingContainer.push(['enableHeartBeatTimer']);

    matomoTrackingContainer.push(['setTrackerUrl', `${BASE_URL}js/tracker.php`]);
    matomoTrackingContainer.push(['setSiteId', matomoSiteId]);
    matomoTrackingContainer.push(['trackPageView']);

    // display in greasemonkey log
    writeToLogConsole('matomoPageTracking', matomoTrackingContainer.getCurrentContainer());
};
