import { Modal } from '@kfz/check-ui/elements/Modal';
import React, { useCallback, useEffect, useState } from 'react';

import { EMPTY_CALCULATION_REENTRY_LINK } from '@oz/shared/out/constants/webapp';
import { ApplicationError } from '@oz/shared/out/enums/ApplicationError';
import { LogType } from '@oz/shared/out/modules/logging/logger';
import { logRequest } from 'modules/logs/logHelper';
import { getClasses } from 'modules/styles/utils';

import { PrimaryButton } from '../Button/Button';
import LoginButton from '../LoginButton/LoginButton';
import ModalHeadline from '../ModalHeadline/ModalHeadline';

import { styles } from './styles';

interface ErrorModalProps {
    errorType: ApplicationError;
    error?: Error | string;
    logError?: boolean;
}

interface ErrorHeadlineProps {
    headline: string;
    errorType: ApplicationError;
}

interface ReEntryLinkProps {
    link?: string;
    lwls?: boolean;
    text?: string;
}

const cssClasses = getClasses<typeof styles>(styles);

const mapErrorTypeToErrorCode = (errorType: ApplicationError): string => {
    switch (errorType) {
        case ApplicationError.CHUNK_LOAD_ERROR:
            return 'CH093';
        case ApplicationError.NETWORK:
            return 'NT72';
        case ApplicationError.RENDER:
            return 'RC77';
        case ApplicationError.DATA_BOOSTRAP:
            return 'DB49';
        case ApplicationError.CORE_VALIDATION:
            return 'CV01';
        case ApplicationError.SESSION_TIMEOUT:
            return 'SM37';
        case ApplicationError.LOAD_VERTRAG:
            return 'KB76';
        case ApplicationError.LOAD_DATA_OZID:
            return 'OI89';
        case ApplicationError.DATA_DELETED:
            return 'DS10';
        case ApplicationError.SAVE_DATA_ERROR:
            return 'SD12';
        case ApplicationError.PREFILL_VID_ACCESS_DENIED:
            return 'KV11';
        case ApplicationError.LOAD_DATA_ACCESS_DENIED:
            return 'LD41';
        case ApplicationError.IDENT_UEBERNEHMEN_FAILED:
            return 'ID13';
        default:
            return 'UN24';
    }
};

const getErrorCode = (type: ApplicationError) => mapErrorTypeToErrorCode(type);

const ErrorHeadline = ({ headline, errorType }: ErrorHeadlineProps) => {
    const errorCode = getErrorCode(errorType);
    return (
        <>
            {errorCode && <span>Code {errorCode}</span>}
            <ModalHeadline>{headline}</ModalHeadline>
        </>
    );
};

const ReloadButton = ({ description }) => (
    <div>
        <PrimaryButton action={() => window.location.reload()} className={cssClasses.button}>
            {description}
        </PrimaryButton>
    </div>
);

interface LognButtonProps {
    closeModal: () => void;
}

const LoginButtonWrapper = ({ closeModal }: LognButtonProps) => {
    const buttonAction = useCallback(() => {
        closeModal();
    }, [closeModal]);

    return (
        <div>
            <LoginButton className={cssClasses.button} action={buttonAction} />
        </div>
    );
};

const ReEntryLink = ({ link = undefined, lwls = true, text = 'neu laden' }: ReEntryLinkProps) => {
    const lwlsParam = lwls ? 'true' : 'false';
    const href = link || `${EMPTY_CALCULATION_REENTRY_LINK}?lwls=${lwlsParam}`;
    return (
        <div>
            <PrimaryButton action={href} className={cssClasses.button}>
                {text}
            </PrimaryButton>
        </div>
    );
};

const ChunkLoadError = () => (
    <>
        <ErrorHeadline headline="Die Online-Zulassung wurde aktualisiert." errorType={ApplicationError.CHUNK_LOAD_ERROR} />
        <p>Bitte laden Sie neu.</p>
        <ReloadButton description="neu laden" />
    </>
);

const noopFunc = () => {};
const getError = (errorType: ApplicationError, error: string, closeCallback = noopFunc) => {
    switch (errorType) {
        case ApplicationError.NETWORK:
            return (
                <>
                    <ErrorHeadline headline="Ihre Internetverbindung wurde unterbrochen" errorType={errorType} />
                    <p>Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.</p>
                    <ReloadButton description="neu laden" />
                </>
            );
        case ApplicationError.CORE_VALIDATION:
        case ApplicationError.SAVE_DATA_ERROR:
            return (
                <>
                    <ErrorHeadline headline="Es ist ein Fehler aufgetreten." errorType={errorType} />
                    <p>Bitte versuchen Sie es erneut.</p>
                    <ReEntryLink lwls={false} />
                </>
            );

        case ApplicationError.SESSION_TIMEOUT:
            return (
                <>
                    <ErrorHeadline headline="Sie waren längere Zeit nicht aktiv." errorType={errorType} />
                    <p>Bitte überprüfen Sie noch einmal Ihre eingegebenen Daten.</p>
                    <ReEntryLink />
                </>
            );

        case ApplicationError.DATA_BOOSTRAP:
            return (
                <>
                    <ErrorHeadline headline="Wir konnten Ihre Daten nicht laden." errorType={errorType} />
                    <p>Bitte versuchen Sie es erneut.</p>
                    <ReEntryLink lwls={false} />
                </>
            );

        case ApplicationError.DATA_DELETED:
            return (
                <>
                    <ErrorHeadline headline="Daten gelöscht" errorType={errorType} />
                    <p>
                        Der Datensatz, den Sie versuchen zu laden, wurde aufgrund der Datenschutz-Grundverordnung (DSGVO)
                        bereits gelöscht.
                        <br />
                        Bitte starten Sie eine neue Online-Zulassung.
                    </p>
                    <ReEntryLink lwls={false} text="Neue Online-Zulassung starten" />
                </>
            );
        case ApplicationError.LOGIN_REQUIRED:
            return (
                <>
                    <ErrorHeadline headline="Bitte melden Sie sich an" errorType={errorType} />
                    <p>Um die Online-Zulassung nutzen zu können, müssen Sie angemeldet sein.</p>
                    <LoginButtonWrapper closeModal={closeCallback} />
                </>
            );
        case ApplicationError.IDENT_UEBERNEHMEN_FAILED:
            return (
                <>
                    <ErrorHeadline headline="Daten übernehmen nicht möglich" errorType={errorType} />
                    <p>Leider konnten wir Ihre Identifikationsdaten nicht übernehmen.</p>
                    <ReloadButton description="zurück zur Übersicht" />
                </>
            );
        case ApplicationError.LOAD_DATA_ACCESS_DENIED:
            return (
                <>
                    <ErrorHeadline headline="Zugriff nicht erlaubt" errorType={errorType} />
                    <p>
                        Bitte melden Sie sich mit dem gleichen Kundenkonto an, mit dem Sie auch Ihren Antrag zur
                        Online-Zulassung abgeschlossen haben. Nur so ist eine Übernahme der angegeben Daten möglich.
                    </p>
                    <LoginButtonWrapper closeModal={closeCallback} />
                </>
            );

        case ApplicationError.PREFILL_VID_ACCESS_DENIED:
            return (
                <>
                    <ErrorHeadline headline="Zugriff nicht erlaubt" errorType={errorType} />
                    <p>
                        Bitte melden Sie sich mit dem gleichen Kundenkonto an, mit dem Sie auch Ihre Kfz-Versicherung
                        abgeschlossen haben. Nur so ist eine Übernahme der angegeben Daten möglich.
                    </p>
                    <LoginButtonWrapper closeModal={closeCallback} />
                </>
            );

        case ApplicationError.CHUNK_LOAD_ERROR:
            return <ChunkLoadError />;
        default:
            return error && error.includes('Loading chunk') ? (
                <ChunkLoadError />
            ) : (
                <>
                    <ErrorHeadline errorType={errorType} headline="Es ist ein Fehler aufgetreten" />
                    <p>Bitte laden Sie die Online Zulassung neu.</p>
                    <ReEntryLink lwls={false} />
                </>
            );
    }
};

const getErrorMessage = (message: string, type: ApplicationError) => {
    return `${getErrorCode(type)}: ${message}`;
};

export default ({ errorType, error, logError = true }: ErrorModalProps) => {
    const [show, setShow] = useState(true);
    const closeLayerCallback = useCallback(() => {
        setShow(false);
    }, []);
    useEffect(() => {
        if (logError && error !== null) {
            if (typeof error === 'string') {
                const err = error as string;
                if (err) {
                    logRequest({ level: LogType.ERROR, message: getErrorMessage(err, errorType) });
                }
            } else {
                const err = error as Error;
                if (err) {
                    logRequest({
                        level: LogType.ERROR,
                        message: getErrorMessage(err.message, errorType),
                        additionalData: { stack: err.stack },
                    });
                }
            }
        }
    }, [error, errorType, logError]);

    return show ? (
        <Modal hideClose ignoreClickOutsideAndEscapeButton noExit>
            <div className={cssClasses.wrapper}>{getError(errorType, String(error), closeLayerCallback)}</div>
        </Modal>
    ) : null;
};
