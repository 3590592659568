// eslint-disable-next-line no-restricted-imports
import { getArrowLeft } from '@kfz/check-ui/utils';

import constants from 'modules/styles/constants';
import { breakpoints, getBreakpoint } from 'modules/styles/mediaQueries';

export const styles = {
    outer: {
        position: 'absolute',
        color: constants.TEXT_DARK,
        fontSize: 11,
        padding: '9px 12px 12px 12px',
        left: 602,
        top: 0,
        width: constants.HELPTEXT_WIDTH_DESKTOP,
        maxWidth: 360,
        ...getBreakpoint(breakpoints.going_wide_medium, {
            left: 602,
        }),

        ...getBreakpoint(breakpoints.medium, {
            display: 'none',
        }),
    },

    content: {
        h3: {
            fontWeight: 'bold',
            fontSize: 12,
            padding: '0 0 3px 0',
            margin: '0 0 7px 0',
        },
        p: {
            paddingBottom: 12,
            margin: '0 0 7px 0',
        },
        a: {
            fontSize: 11,
        },
        span: {
            fontSize: 11,
        },
        img: {
            maxWidth: '100%',
        },
    },

    corner: {
        left: -30,
        top: 5,
        ...getArrowLeft(14, 14, 1, '#fafafa', true),
        ...getBreakpoint(breakpoints.medium, {
            display: 'none',
        }),
    },
};
