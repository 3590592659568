import { EntryType } from '@oz/shared/out/enums/EntryType';
import { isOneOf } from '@oz/shared/out/modules/enum';
import { getEntry } from 'modules/applicationConfig';

import { OzRoute } from '../enums/OzRoute';

export default (): OzRoute => {
    const entry = getEntry();

    if (isOneOf(entry?.type, [EntryType.OZID, EntryType.BACKOFFICE])) {
        return OzRoute.HUB;
    }

    if (isOneOf(entry?.type, [EntryType.CANCEL_ANTRAG])) {
        return OzRoute.BEREITS_ZUGELASSEN;
    }

    return OzRoute.DATENEINGABE;
};
