import { Location } from 'react-router-dom';

import { EntryType } from '@oz/shared/out/enums/EntryType';
import { Section } from '@oz/shared/out/enums/Section';
import { isOneOf } from '@oz/shared/out/modules/enum';

import { OzRoute } from '../../enums/OzRoute';

interface TrackingConfig {
    pageTrackingName: string;
    gtActionId: number | undefined;
}

type TrackingConValue = TrackingConfig | ((entryType: EntryType) => TrackingConfig);

type TrackingMapping = Record<string, TrackingConValue>;

const SectionTrackingNameMappping: TrackingMapping = {
    [Section.HALTER]: { pageTrackingName: 'ozHalter.htm', gtActionId: 2196 },
    [Section.FAHRZEUG]: { pageTrackingName: 'ozFahrzeug.htm', gtActionId: 2197 },
    [Section.FAHRZEUGPAPIERE]: { pageTrackingName: 'ozFahrzeugpapiere.htm', gtActionId: 2198 },
    [Section.ZAHLUNGSDATEN]: { pageTrackingName: 'ozZahlungsdaten.htm', gtActionId: 2199 },
    [Section.IDENTIFIKATION]: { pageTrackingName: 'ozIdentifikation.htm', gtActionId: 2200 },
};

const SectionRouteMapping: TrackingMapping = {
    [OzRoute.UEBERSICHT]: { pageTrackingName: 'ozUebersicht.htm', gtActionId: 2201 },
    [OzRoute.HUB]: entryType =>
        isOneOf(entryType, [EntryType.VERTRAG, EntryType.EMPTY])
            ? { pageTrackingName: 'ozHub.htm', gtActionId: 2202 }
            : { pageTrackingName: 'ozHubReEntry.htm', gtActionId: 2265 },
    [OzRoute.BEREITS_ZUGELASSEN]: { pageTrackingName: 'bereitsZugelassen.htm', gtActionId: undefined },
};

export const getTrackingConfig = (section: Section, route: OzRoute, entryType: EntryType) => {
    const conf = SectionTrackingNameMappping[section] || SectionRouteMapping[route] || {};
    if (typeof conf === 'function') {
        return conf(entryType);
    }
    return conf;
};

export const getCurrentSection = (location: Location | typeof window.location): Section => {
    const search = new URLSearchParams(location.search);
    const sectionName = search.get('section') as Section;

    return sectionName;
};
