import { useForm } from '@kfz/check-ui/context/FormContext';
import { HelpButton } from '@kfz/check-ui/elements/HelpButton';
import { Modal, MODAL_THEME } from '@kfz/check-ui/elements/Modal';
import { IconVariant, Tooltip } from '@kfz/check-ui/elements/Tooltip';
// eslint-disable-next-line no-restricted-imports
import { getTestId } from '@kfz/check-ui/utils';
import React, { ComponentType, FunctionComponent, JSX, useCallback, useState } from 'react';

import { getClasses, mergeClasses } from 'modules/styles/utils';

import useIsInlineHelp from '../../../../hooks/useIsInlineHelp';
import HelpText from '../../HelpText/HelpText';

import { styles } from './styles.device';

interface WithHelpTextProps {
    helpText?: JSX.Element;
    name: string;
    testId?: string;
    helpButtonClassName?: string;
    helpAsTip?: boolean;
}

const classNames = getClasses<typeof styles>(styles);

interface HelpButtonWrapperProps {
    helpWrapperCssClass: string;
    helpText?: JSX.Element;
    name: string;
    helpButtonTestId?: string;
    helpButtonOnClick?: () => void;
    helpAsTip?: boolean;
}

const HelpButtonWrapper = ({
    helpWrapperCssClass,
    helpText,
    name,
    helpButtonTestId,
    helpButtonOnClick,
    helpAsTip,
}: HelpButtonWrapperProps) => {
    const hasHelp = Boolean(helpText);
    if (helpAsTip) {
        return (
            <div className={helpWrapperCssClass}>
                <div className={classNames.wrapper}>
                    {hasHelp && (
                        <Tooltip iconSize={20} iconVariant={IconVariant.QUESTIONMARK} contentWidth={500}>
                            {helpText}
                        </Tooltip>
                    )}
                </div>
            </div>
        );
    }
    return (
        <div className={helpWrapperCssClass}>
            <div className={classNames.wrapper}>
                {hasHelp && <HelpButton name={name} testId={helpButtonTestId} customOnClick={helpButtonOnClick} />}
            </div>
        </div>
    );
};

const WithHelpText = <Props extends {}>(
    WrappedComponent: ComponentType<Props>
): FunctionComponent<Props & WithHelpTextProps> => {
    const ComponentWithHelptext = ({
        helpText,
        name,
        testId,
        helpButtonClassName = '',
        helpAsTip,
        ...props
    }: WithHelpTextProps) => {
        const {
            state: { activeRow },
            dispatch,
        } = useForm();
        const showInline = useIsInlineHelp();
        const [showHelpModal, setShowHelpModal] = useState(false);
        const isActive = activeRow === name && Boolean(helpText);
        const helpTextTestId = getTestId(testId, 'help/text');
        const helpButtonTestId = getTestId(testId, 'help/button');
        const helpButtonOnClick = showInline ? () => setShowHelpModal(true) : undefined;

        const helpWrapperCssClass = mergeClasses(classNames.helpWrapper, helpButtonClassName);

        const setRowActive = useCallback(() => {
            if (!showInline) {
                dispatch({ name: 'setActiveRow', payload: { activeRow: name } });
            }
        }, [name, showInline, dispatch]);

        const onCloseModal = useCallback(() => {
            setShowHelpModal(false);
        }, []);

        return (
            <>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div className={classNames.rowControl} onClick={setRowActive}>
                    <div className={classNames.controlWrapper}>
                        {/* @ts-expect-error -- Problem mit der type union aber das passt schon */}
                        <WrappedComponent {...props} name={name} />
                    </div>

                    <HelpButtonWrapper
                        name={name}
                        helpButtonTestId={helpButtonTestId}
                        helpButtonOnClick={helpButtonOnClick}
                        helpText={helpText}
                        helpWrapperCssClass={helpWrapperCssClass}
                        helpAsTip={helpAsTip}
                    />

                    {!showInline && !helpAsTip && isActive && <HelpText testId={helpTextTestId}>{helpText}</HelpText>}
                    {showInline && showHelpModal && (
                        <Modal onModalClose={onCloseModal} theme={MODAL_THEME.FLOATING}>
                            <div className={classNames.modalHelpContent}>{helpText}</div>
                        </Modal>
                    )}
                </div>
            </>
        );
    };

    return ComponentWithHelptext;
};

export default WithHelpText;
