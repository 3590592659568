import { Dispatch } from 'redux';

import { DatenModellSegment } from '@oz/shared/out/enums/DatenModellSegment';
import { ErrorKey } from '@oz/shared/out/enums/ErrorKey';
import { ObjectSearchPath } from '@oz/shared/out/types/ObjectSearchPath';
import { isValidPlz } from '@oz/shared/out/validators/validatePlz';
import { registerDataModelListener } from 'store/sideEffects/onStoreChange';
import getOrtOptionsFromPlz from 'store/sideEffects/util/getOrtOptionsFromPlz';
import { setOptions } from 'store/slices/dynamicOptionsSlice';
import { setError } from 'store/slices/fieldErrorsSlice';

import { ListenerCallbackItem } from '../../../interfaces/ListenerCallbackItem';

export default (basePath: ObjectSearchPath, dispatch: Dispatch): void => {
    const plzPath = [...basePath, DatenModellSegment.ORT, DatenModellSegment.PLZ] as ObjectSearchPath;
    const ortePath = [...basePath, DatenModellSegment.ORT, DatenModellSegment.NAME] as ObjectSearchPath;

    const setInvalid = (key: ErrorKey): void => {
        dispatch(setError({ path: plzPath, fieldValidation: { errorKey: key } }));
        dispatch(setOptions({ path: ortePath, options: [] }));
    };

    registerDataModelListener<[ListenerCallbackItem<string>]>([plzPath], async ([{ value: plz }], { initial }) => {
        if (initial) {
            return;
        }

        if (plz && plz.length === 5) {
            if (!isValidPlz(plz)) {
                setInvalid(ErrorKey.INVALID_PLZ);
                return;
            }
            const options = await getOrtOptionsFromPlz(plz);
            if (!options.length) {
                setInvalid(ErrorKey.INVALID_PLZ);
                return;
            }

            dispatch(setOptions({ path: ortePath, options }));
            dispatch(setError({ path: ortePath, fieldValidation: undefined }));

            return;
        }
        dispatch(setOptions({ path: ortePath, options: [] }));
        dispatch(setError({ path: ortePath, fieldValidation: undefined }));
    });
};
