import React from 'react';
import { createPortal } from 'react-dom';

import { CONTACT_EMAIL } from '@oz/shared/out/constants/webapp';
import { getClasses } from 'modules/styles/utils';

import { styles } from './styles.device';

const cssClasses = getClasses<typeof styles>(styles);

const WF_BUBBLE_CONTENT_ID = 'chatty-chatbot-layer';

export default () => {
    const portalElement = document.getElementById(WF_BUBBLE_CONTENT_ID);
    if (!portalElement) {
        return null;
    }
    return createPortal(
        <div className={cssClasses.wrapper}>
            <div>
                Gerne können Sie uns per E-Mail kontaktieren.
                <br /> Bitte geben Sie falls möglich Ihre Referenznummer an:
                <br />
                <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
            </div>
        </div>,
        portalElement
    );
};
