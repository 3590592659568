import { RefObject } from 'react';

import { getRectFromRef } from 'modules/utils/dimension';

const PX = 'px';

type HtmlRef = RefObject<HTMLElement>;

// @ts-ignore
const getRootElem = (): HTMLElement => document.getElementById('c24_oz_app'); // TODO make util

export const resetRootElem = (): void => {
    const rootElement = getRootElem();
    rootElement.style.height = ''; // reset
};

export const setHelpPos = (helpTextRef: HtmlRef, helpTextNoseRef: HtmlRef, sectionRef: HtmlRef): void => {
    if (helpTextRef && helpTextRef.current && sectionRef && sectionRef.current) {
        const rootElement = getRootElem();
        const helpNose = helpTextNoseRef.current;
        const helpElement = helpTextRef.current;
        rootElement.style.height = ''; // reset
        helpElement.style.top = '';
        // @ts-ignore
        helpNose.style.top = '';
        const rootBox = rootElement.getBoundingClientRect();
        const helpBox = helpElement.getBoundingClientRect();
        let addToRootHeight;

        const rootBottom = rootBox.bottom;
        const helpBottom = helpBox.bottom;

        if (helpBottom > rootBottom) {
            // adjust dialog content height, if the help text is larger than the dialog content
            addToRootHeight = helpBottom - rootBottom;
        }

        const sectionRect = getRectFromRef(sectionRef);
        const helpRect = getRectFromRef(helpTextRef);

        const topDiff = sectionRect && helpRect ? sectionRect.bottom - helpRect.bottom + 24 : 0;
        let wrapperTop = topDiff;
        let noseTop = topDiff * -1 + 24 - 11;

        // @ts-ignore
        if (sectionRect.top >= wrapperTop + helpRect.top) {
            // @ts-ignore
            const topSectionDiff = sectionRect.top - (wrapperTop + helpRect.top);
            wrapperTop += topSectionDiff;
            noseTop -= topSectionDiff;
        }

        // @ts-ignore
        if (topDiff < 0 || sectionRect.top >= wrapperTop + helpRect.top) {
            helpElement.style.top = wrapperTop + PX;
            // @ts-ignore
            helpNose.style.top = noseTop + PX;
        }

        if (addToRootHeight) {
            rootElement.style.height = rootBox.height + addToRootHeight + PX;
        }
    }
};
