// @ts-nocheck

import { PRODUCT_SSO_KEY } from '@oz/shared/out/constants/webapp';

export default () => {
    try {
        window.C24App.setProduct({ productSSO: PRODUCT_SSO_KEY, title: 'Online-Zulassung' });
    } catch (e) {
        // mei ...
    }
};
