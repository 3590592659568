import { DeviceOutput } from '@kfz/web-toolkit/client';

import { PRODUCT_SSO_KEY, WEBAPP_BASE_PATH } from '@oz/shared/out/constants/webapp';
import { isOneOf } from '@oz/shared/out/modules/enum';
import { isDev, isProd } from '@oz/shared/out/modules/environment';
import { LogType } from '@oz/shared/out/modules/logging/logger';
import { postRequest } from 'modules/ajax';
import { isMobile, getDevice } from 'modules/applicationConfig';
import { logRequest } from 'modules/logs/logHelper';
import isInIframe from 'modules/utils/isInIframe';

const getEnv = () => (isProd() ? 'prod' : 'test');
const getLoginLayerDevice = () => (isMobile() ? 'mobile' : 'desktop');

const getLoginConfig = (username: string, isPhoneLogin: boolean) => {
    if (isPhoneLogin) {
        return {
            login_phone: username,
            login_email: '',
            login_type: 'check24_phone',
        };
    }

    return {
        login_phone: '',
        login_email: username,
        login_type: 'check24',
    };
};

let callbackRegistered = false;

const isLoginLayerAllowed = (device: DeviceOutput): boolean => {
    if (isInIframe()) {
        return false;
    }

    if (isOneOf(device, [DeviceOutput.APP, DeviceOutput.TABLETAPP])) {
        return false;
    }

    return true;
};

export const initializeLoginLayer = (username = '', isPhoneLogin = false) => {
    if (!isLoginLayerAllowed(getDevice())) {
        return;
    }

    try {
        window.Check24.uliloginlayer.config.env = getEnv(); // default value is "prod" / can be changed to "int" or "test"
        window.Check24.uliloginlayer.init({
            deviceoutput: getLoginLayerDevice(), // can be mobile or desktop
            api_product: PRODUCT_SSO_KEY, // add your SSO-api-product-name
            ...getLoginConfig(username, isPhoneLogin),
        });

        // do this only once
        if (!callbackRegistered) {
            callbackRegistered = true;
            window.addEventListener('uli-login-layer', async evt => {
                // @ts-ignore
                const { SSO_SESSION, STATE } = evt.detail.ul;

                if (SSO_SESSION || STATE === 'loggedin') {
                    const sessionToPass = isDev() ? SSO_SESSION : null;
                    if (sessionToPass) {
                        await postRequest(`${WEBAPP_BASE_PATH}/ajax/login`, sessionToPass);
                    }
                    window.location.reload();
                }
            });
        }
    } catch (e) {
        // no login layer possible
        logRequest({
            level: LogType.ERROR,
            message: `Cannot initialize login layer: ${(e as Error).message}`,
            additionalData: { device: getLoginLayerDevice(), env: getEnv() },
        });
    }
};

export const openLoginLayer = () => {
    window.scrollTo(0, 0);
    window.Check24.uliloginlayer.open();
};
