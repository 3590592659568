import { Tracking } from '@oz/shared/out/interfaces/Tracking';

interface MatomoTrackingData {
    cpid: string;
    cpref: string;
    matomoSiteId: string;
    trackingId: string;
}

export default (trackingModell: Tracking): MatomoTrackingData => {
    const { cpid, cpref, id: trackingId, matomoSiteId } = trackingModell;

    return {
        cpref: cpref || '',
        cpid: cpid || '',
        trackingId,
        matomoSiteId: String(matomoSiteId),
    };
};
