import { Dispatch } from 'redux';

import { DatenModellSegment } from '@oz/shared/out/enums/DatenModellSegment';
import ortnamenLookup from 'store/sideEffects/effects/ortnamenLookup';
import strassennamenLookup from 'store/sideEffects/effects/strassennamenLookup';
import zulassungstellenLookup from 'store/sideEffects/effects/zulassungstellenLookup';

// eslint-disable-next-line
export default (dispatch: Dispatch): void => {
    ortnamenLookup([DatenModellSegment.HALTER, DatenModellSegment.ADRESSE], dispatch);
    ortnamenLookup([DatenModellSegment.HALTER, DatenModellSegment.ABWEICHENDE_LIEFERADRESSE], dispatch);
    strassennamenLookup([DatenModellSegment.HALTER, DatenModellSegment.ADRESSE], dispatch);
    strassennamenLookup([DatenModellSegment.HALTER, DatenModellSegment.ABWEICHENDE_LIEFERADRESSE], dispatch);
    zulassungstellenLookup(dispatch);
};

// usage:
/*
registerDataModelListener<[ListenerCallbackItem<Situation>, ListenerCallbackItem<Halter>]>(
    [
        [DatenModellSegment.BERECHNEN, DatenModellSegment.SITUATION],
        [DatenModellSegment.BERECHNEN, DatenModellSegment.HALTER],
    ],
    (values, { initial, state }) => {
        console.log({ initial, berechnen: state.datenModell.berechnen });
        const [situation, halter] = values;
        // const val = situation.value;
        console.log('callback triggered', initial);
        console.log(situation.value);
        console.log(halter.value);

        console.log(situation.changed);
        console.log(halter.changed);
    }
);
*/
