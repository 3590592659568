import { Styles } from '@kfz/check-ui/types';

export const breakpoints = {
    full: 1280,
    going_wide_medium: 1200,
    full_narrow: 994,
    medium: 939,
    medium_narrow_wide: 852,
    medium_narrow: 767,
    small: 600,
    small_narrow: 550,
};

export const getBreakpoint = (breakpoint: number, props: Styles): Styles => {
    return { [`@media (max-width: ${breakpoint}px)`]: props };
};

export const isBelowBreakpoint = (windowWidth: number, breakpoint: number) => {
    return windowWidth < breakpoint;
};
