import React from 'react';

import { getClasses } from 'modules/styles/utils';

import Check from '../../../assets/svg/Check.svg';

import { styles } from './styles.device';

const cssClasses = getClasses<typeof styles>(styles);

export enum ChecklistTheme {
    CHECKMARK,
    NUMBER,
}

interface CheckListProps {
    items: (string | JSX.Element)[];
    theme?: ChecklistTheme;
}

const CheckList = ({ items, theme = ChecklistTheme.CHECKMARK }: CheckListProps) => {
    const isCheckmarkTheme = theme === ChecklistTheme.CHECKMARK;
    const rowClass = isCheckmarkTheme ? cssClasses.row : cssClasses.rowNumberTheme;
    const iconClass = isCheckmarkTheme ? cssClasses.icon : cssClasses.iconNumberTheme;
    const textClass = isCheckmarkTheme ? cssClasses.text : cssClasses.textNumberTheme;
    return (
        <ul className={cssClasses.wrapper}>
            {items.map((item, index) => (
                <li key={index} className={rowClass}>
                    {isCheckmarkTheme ? <Check className={iconClass} /> : <div className={iconClass}>{index + 1}</div>}
                    <span className={textClass}>{item}</span>
                </li>
            ))}
        </ul>
    );
};

export default CheckList;
