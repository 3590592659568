import isRunIdentInPopup from '@oz/shared/out/util/isRunIdentInPopup';
import { isApp, isDesktop } from 'modules/applicationConfig';

export const isRunningInApp = () => {
    return isApp();
};

export const isStartIdentInPopup = () => {
    return isRunIdentInPopup(isDesktop(), isApp(), navigator.userAgent);
};
