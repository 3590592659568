import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { ApplicationState } from '../interfaces/ApplicationState';

import { initialize, onStoreChange } from './sideEffects/onStoreChange';
import applicationError from './slices/applicationErrorSlice';
import datenModell from './slices/datenModellSlice';
import dynamicOptions from './slices/dynamicOptionsSlice';
import fieldErrors from './slices/fieldErrorsSlice';
import sections from './slices/sectionsSlice';
import ssoModell from './slices/ssoModellSlice';
import zulassungsstelle from './slices/zulassungsstelleSlice';

const reducer = combineReducers({
    datenModell,
    applicationError,
    ssoModell,
    fieldErrors,
    dynamicOptions,
    zulassungsstelle,
    sections,
});

let store: EnhancedStore<ApplicationState>;
export const initializeReduxStore = (initial: ApplicationState): EnhancedStore<ApplicationState> => {
    if (!store) {
        // can be initialized only once
        // @ts-ignore
        store = configureStore<ApplicationState>({
            // @ts-ignore
            reducer,
            preloadedState: initial,
        });
        initialize(store.getState);
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const unsubscribe = store.subscribe(() => {
            onStoreChange();
        });
    }
    return store;
};
