import { SESSION } from '@oz/shared/out/constants/getParameterNames';

export default (route: string, sessionId?: string, field?: string): string => {
    const params = new URLSearchParams();

    if (sessionId) {
        params.set(SESSION, sessionId);
    }

    if (field) {
        params.set('field', field);
    }
    return `${route}?${params.toString()}`;
};
