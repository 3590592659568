// polyfills, see https://babeljs.io/docs/en/babel-polyfill
import 'core-js/stable';
import { CheckUIThemeContextProvider } from '@kfz/check-ui/context/ThemeContext';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { WEBAPP_BASE_PATH } from '@oz/shared/out/constants/webapp';
import { getDeviceType } from 'modules/applicationConfig';
import initializeApp from 'modules/initializeApp';
import { initializeLoginLayer } from 'modules/loginLayer';
import { injectGlobalStyles } from 'modules/styles/globalStylesFromLayout';

import Bootstrapper from './components/common/Bootstrapper/Bootstrapper';
import HelpBubble from './components/common/HelpBubble/HelpBubble';
import LoginScreen from './components/common/LoginScreen/LoginScreen';
import { OzRoute } from './enums/OzRoute';

// @ts-ignore
const forceLogin = () => !!window?.KfzOz?.fclgn;

function renderApp() {
    // set to guest initially to prevent all versions showing, will be set correctly after model request
    window.KFZ.setLoginStatus('GUEST');
    injectGlobalStyles();
    // initializeSentryClient();
    // @ts-ignore
    createRoot(document.getElementById('c24_oz_app')).render(
        forceLogin() ? (
            <CheckUIThemeContextProvider device={getDeviceType()} theme={{}}>
                <LoginScreen />
                <HelpBubble />
            </CheckUIThemeContextProvider>
        ) : (
            <BrowserRouter basename={`${WEBAPP_BASE_PATH}${OzRoute.DEFAULT}`}>
                <Bootstrapper />
                <HelpBubble />
            </BrowserRouter>
        )
    );

    // This forces iOS to reload the page on browser back
    window.addEventListener(
        'pageshow',
        evt => {
            if (evt.persisted) {
                window.location.reload();
            }
        },
        false
    );

    window.addEventListener('load', () => {
        initializeLoginLayer();
        initializeApp();
    });
}

renderApp();
