import constants from 'modules/styles/constants';

export const styles = {
    headline: {
        fontSize: 20,
        lineHeight: '24px',
        marginBottom: 12,
        color: constants.TEXT_DARK,
        fontFamily: 'Verdana',
    },
};
