import { useCallback, useEffect, useState } from 'react';

const getCurrentWindowWidth = (): number => {
    return window.innerWidth;
};

export default function useWindowWidth(): number {
    const [width, setWidth] = useState(getCurrentWindowWidth());

    const handleResize = useCallback(() => {
        setWidth(getCurrentWindowWidth());
    }, [setWidth]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);
    return width;
}
