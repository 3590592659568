import { SM_KEY } from '@oz/shared/out/constants/webapp';
import { AjaxEndpoint } from '@oz/shared/out/enums/AjaxEndpoint';
import { ApplicationError } from '@oz/shared/out/enums/ApplicationError';
import { HttpMethod } from '@oz/shared/out/enums/HttpMethod';
import { RequestStatus } from '@oz/shared/out/enums/RequestStatus';
import { AjaxResponse } from '@oz/shared/out/interfaces/AjaxResponse';
import { getApiKeys } from 'modules/applicationConfig';
import { getSession, getSessionMatcher } from 'modules/utils/session';

const REQUEST_MAX_TRIES = 5;
const RETRY_WAIT = 1000; // ms
const NETWORK_ERROR_WORDS = [
    'fetch',
    'Network',
    'Anforderung',
    'Internet',
    'Server',
    'Verbindung',
    'Protokoll',
    'Netzwerk',
    'Abgebrochen',
];

function delay(ms) {
    const p = new Promise(resolve => {
        setTimeout(() => {
            resolve(true);
        }, ms);
    });

    return p;
}

function isNetworkError(errorMessage) {
    return NETWORK_ERROR_WORDS.some(item => errorMessage.includes(item));
}

export const getRequest = async <ResponseType>(url: string): Promise<AjaxResponse<ResponseType>> => {
    // implement params if necessary
    const headers = new Headers();
    headers.set('X-Requested-With', 'XMLHttpRequest');
    const resp = await fetch(url, {
        method: 'get',
        headers,
    });
    const data = await resp.json();
    return { ...data, statusCode: resp.status } as AjaxResponse<ResponseType>;
};

export const postRequest = async <RequestType, ResponseType>(
    url: string,
    params: RequestType
): Promise<AjaxResponse<ResponseType>> => {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('X-Requested-With', 'XMLHttpRequest');

    const session = getSession();
    const sessionMatcher = getSessionMatcher();
    const resp = await fetch(url, {
        method: 'post',
        headers,
        body: JSON.stringify({ [SM_KEY]: sessionMatcher, session, requestData: params }),
    });
    const data = await resp.json();
    return { ...data, statusCode: resp.status } as AjaxResponse<ResponseType>;
};

type RequestFunction<ResponseType, RequestType> = (params: RequestType) => Promise<AjaxResponse<ResponseType>>;

const requestFunctionsCache = {};

export const makeRequestFunction = <RequestType, ResponseType>(
    key: AjaxEndpoint,
    logErrors = true
): RequestFunction<ResponseType, RequestType> => {
    // Some simple memoization
    const cachedFunc = requestFunctionsCache[key];
    if (cachedFunc) {
        return cachedFunc;
    }

    const endpoint = getApiKeys().find(apiKey => apiKey.key === key);
    if (!endpoint) {
        throw new Error(
            `Cannot generate ajax function for ${key}. Looks like the ApplicationConfig was not initialized yet`
        );
    }
    const { method, path } = endpoint;
    const requestFunction = async (params: RequestType) => {
        let tryCount = 0;

        const doRequest = async () => {
            tryCount += 1;
            try {
                if (method === HttpMethod.GET) {
                    return await getRequest<ResponseType>(path);
                }
                return await postRequest<RequestType, ResponseType>(path, params);
            } catch (e) {
                const err = e as Error;
                const hasNetworkError = isNetworkError(err.message);
                if (hasNetworkError && tryCount < REQUEST_MAX_TRIES) {
                    await delay(RETRY_WAIT);
                    return doRequest();
                }

                if (logErrors) {
                    // throw new Error(hasNetworkError ? ApplicationError.NETWORK : err);
                    return {
                        requestStatus: RequestStatus.ERROR,
                        message: hasNetworkError ? ApplicationError.NETWORK : err.message,
                        payload: undefined,
                        statusCode: 408,
                    };
                }
            }

            return {} as AjaxResponse<ResponseType>;
        };

        return doRequest();
    };
    requestFunctionsCache[key] = requestFunction;
    return requestFunction;
};
