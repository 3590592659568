import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoginStatus } from '@oz/shared/out/enums/LoginStatus';
import { SsoModell } from '@oz/shared/out/interfaces/SsoModell';

type NullableSsoModell = SsoModell | null;

// Slice
const slice = createSlice({
    name: 'ssoModell',
    initialState: null as NullableSsoModell,
    reducers: create => ({
        initializeSsoModell: create.reducer((state: NullableSsoModell, action: PayloadAction<SsoModell>): SsoModell => {
            return action.payload;
        }),
        setLoginStatusExpired: create.reducer((state: NullableSsoModell): SsoModell => {
            return { ...state, loginStatus: LoginStatus.EXPIRED };
        }),
    }),
});
export default slice.reducer;

// Actions
export const { initializeSsoModell, setLoginStatusExpired } = slice.actions;
