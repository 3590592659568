const setVal = (val: string, id: string) => {
    const target = document.getElementById(id);
    if (target) {
        target.innerHTML = val;
    }
};

export const setRefnum = (val: string) => {
    setVal(val, 'c24_kfz_gm_refnum');
};

export const setId = (val: string) => {
    setVal(val, 'c24_kfz_gm_id');
};

const containerId = 'greaseLog';
export const writeToLogConsole = (type: string, arr: (string | number)[][]): void => {
    const logContainer = document.getElementById(containerId);

    if (logContainer) {
        const newBreakLineEl = () => document.createElement('br');
        const messageToAppend = document.createElement('span');

        arr.forEach(item => {
            messageToAppend.append(Array.isArray(item) ? item.join(' : ') : item, newBreakLineEl());
        });

        messageToAppend.append(newBreakLineEl(), '------------', newBreakLineEl());

        logContainer.prepend(messageToAppend);
    }
};
