import React from 'react';

import constants from 'modules/styles/constants';
import { getClasses } from 'modules/styles/utils';

import { getStyles } from './styles';

interface SpinnerProps {
    width?: number;
    height?: number;
    borderWidth?: number;
    left?: number;
    top?: number;
    color?: string;
}

const Spinner = React.memo(
    ({ width = 24, height = 24, borderWidth = 4, color = constants.WHITE, left = 5, top }: SpinnerProps) => {
        // @ts-ignore
        const styles = getStyles(width, height, borderWidth, color, left, top);

        const cssClasses = getClasses<typeof styles>(styles);

        return (
            <div className={cssClasses.wrapper}>
                <div className={cssClasses.inner} />
            </div>
        );
    }
);

export default Spinner;
