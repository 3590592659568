import { DeviceOutput } from '@kfz/web-toolkit/client';
import { EnhancedStore } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import {
    HTTP_STATUS_ACCESS_DENIED,
    HTTP_STATUS_BAD_REQUEST,
    HTTP_STATUS_LEGAL_REASONS,
} from '@oz/shared/out/constants/httpStatus';
import { EMPTY_CALCULATION_REENTRY_LINK, WEBAPP_BASE_PATH } from '@oz/shared/out/constants/webapp';
import { ApplicationError } from '@oz/shared/out/enums/ApplicationError';
import { DatenModellSegment } from '@oz/shared/out/enums/DatenModellSegment';
import { LoginStatus } from '@oz/shared/out/enums/LoginStatus';
import { RequestStatus } from '@oz/shared/out/enums/RequestStatus';
import { Section } from '@oz/shared/out/enums/Section';
import { BootstrapModell } from '@oz/shared/out/interfaces/BootstrapModell';
import { DatenModellHolder } from '@oz/shared/out/interfaces/DatenModellHolder';
import { SsoModell } from '@oz/shared/out/interfaces/SsoModell';
import { SsoUser } from '@oz/shared/out/interfaces/SsoUser';
import { LogType } from '@oz/shared/out/modules/logging/logger';
import { makeKey } from '@oz/shared/out/modules/object/makeKey';
import { getFromDatenModell } from '@oz/shared/out/selectors/datenModell';
import { ObjectSearchPath } from '@oz/shared/out/types/ObjectSearchPath';
import { postRequest } from 'modules/ajax';
import { getDevice, initializeApplicationConfig } from 'modules/applicationConfig';
import { logRequest } from 'modules/logs/logHelper';
import { getStateFromSessionStorage } from 'modules/storageSync/sessionStorage';
import hideSpinner from 'modules/utils/hideSpinner';
import { initializeReduxStore } from 'store/initialize';
import getOrtOptionsFromPlz from 'store/sideEffects/util/getOrtOptionsFromPlz';

import App from '../../../App';
import { ApplicationState } from '../../../interfaces/ApplicationState';
import { SectionsState } from '../../../interfaces/SectionsState';
import ErrorModal from '../ApplicationError/ErrorModal';

const MODELL_ENDPOINT = `${WEBAPP_BASE_PATH}/ajax/modell`;

const getPlzOptions = async (state: DatenModellHolder, ortPath: ObjectSearchPath) => {
    const plz = getFromDatenModell<string>(ortPath)(state);
    if (plz) {
        // lookup
        return getOrtOptionsFromPlz(plz);
    }
    return [];
};

const HALTER_ORT_PATH = [DatenModellSegment.HALTER, DatenModellSegment.ADRESSE, DatenModellSegment.ORT];
const LIEFERADRESSE_ORT_PATH = [
    DatenModellSegment.HALTER,
    DatenModellSegment.ABWEICHENDE_LIEFERADRESSE,
    DatenModellSegment.ORT,
];
export const getInitialDynamicOptions = async (state: DatenModellHolder) => {
    const halterOrtOptions = await getPlzOptions(state, [...HALTER_ORT_PATH, DatenModellSegment.PLZ]);
    const lieferadresseOrtOptions = await getPlzOptions(state, [...LIEFERADRESSE_ORT_PATH, DatenModellSegment.PLZ]);
    const dynamicOptions = {
        [makeKey([...HALTER_ORT_PATH, DatenModellSegment.NAME])]: halterOrtOptions,
        [makeKey([...LIEFERADRESSE_ORT_PATH, DatenModellSegment.NAME])]: lieferadresseOrtOptions,
    };

    return dynamicOptions;
};

const initialSectionState: SectionsState = {
    sequentialMode: true,
    sections: [
        {
            section: Section.HALTER,
            editable: true,
        },
        {
            section: Section.FAHRZEUG,
            editable: false,
        },
        {
            section: Section.FAHRZEUGPAPIERE,
            editable: false,
        },
        {
            section: Section.ZAHLUNGSDATEN,
            editable: false,
        },
        {
            section: Section.IDENTIFIKATION,
            editable: false,
        },
    ],
};

const getInitialState = async (serverModell: BootstrapModell): Promise<ApplicationState> => {
    const { datenModell, ssoModell, sessionInfo } = serverModell;

    const { sessionId } = sessionInfo;
    const fromSessionStorage = getStateFromSessionStorage(sessionId);
    if (fromSessionStorage) {
        return fromSessionStorage;
    }
    return {
        datenModell,
        ssoModell,
        fieldErrors: {},
        dynamicOptions: await getInitialDynamicOptions({ datenModell }),
        sections: initialSectionState,
    };
};

const getUsername = (ssoUser?: SsoUser) => {
    if (!ssoUser) {
        return '';
    }

    const { phoneLogin, emailLogin, vorname, familienname } = ssoUser;

    if (vorname && familienname) {
        return `${vorname} ${familienname}`;
    }

    return emailLogin || phoneLogin;
};

const setWireframeUser = (ssoModell: SsoModell) => {
    try {
        const { loginStatus, user } = ssoModell;
        const loggedIn = loginStatus === LoginStatus.LOGGED_IN;
        const wfStatus = loggedIn ? 'USER' : 'GUEST';
        window.KFZ.setLoginStatus(wfStatus, getUsername(user));
    } catch (e) {
        // oh well
    }
};

const setWireframeRefNum = (refNum?: string) => {
    try {
        window.KFZ.setReferenznummer(refNum);
    } catch (e) {
        // oh well
    }
};

const Bootstrapper = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | undefined>();
    const [errorCode, setErrorCode] = useState<number | undefined>();
    const [store, setStore] = useState<EnhancedStore<ApplicationState>>();

    useEffect(() => {
        (async () => {
            try {
                const response = await postRequest<DeviceOutput, BootstrapModell>(MODELL_ENDPOINT, getDevice());
                setLoading(false);
                hideSpinner();

                // init application config, init state
                if (response.requestStatus === RequestStatus.SUCCESS && response.payload) {
                    const {
                        prefilledHalterFields,
                        sessionInfo,
                        trackingData,
                        apiKeys,
                        ssoModell,
                        uiInfo,
                        bankVorschlaege,
                        datenModell,
                    } = response.payload;
                    initializeApplicationConfig({
                        sessionInfo,
                        tracking: trackingData,
                        apiKeys,
                        uiInfo,
                        bankVorschlaege,
                        prefilledHalterFields,
                    });
                    setStore(initializeReduxStore(await getInitialState(response.payload)));
                    setWireframeUser(ssoModell);
                    setWireframeRefNum(datenModell.metaDaten.ozRefNum);
                    return;
                }

                setError(response.message);
                setErrorCode(response.statusCode);
            } catch (e) {
                const err = e as Error;
                logRequest({ level: LogType.WARNING, message: `Cannot Bootstrap: ${err.message}` });
                setLoading(false);
                setError(err.message);
                // @ts-ignore
                setErrorCode(err.response.status);
            }
        })();
    }, []);

    if (!loading) {
        hideSpinner();
    }

    if (error) {
        if (errorCode === HTTP_STATUS_BAD_REQUEST) {
            return <ErrorModal errorType={ApplicationError.SESSION_TIMEOUT} logError={false} />;
        }

        if (errorCode === HTTP_STATUS_LEGAL_REASONS) {
            return <ErrorModal errorType={ApplicationError.DATA_DELETED} logError={false} />;
        }

        if (errorCode === HTTP_STATUS_ACCESS_DENIED) {
            window.location.href = EMPTY_CALCULATION_REENTRY_LINK;
            return null;
        }

        return (
            <ErrorModal error={`Init Model failed: ${error}`} errorType={ApplicationError.DATA_BOOSTRAP} logError={false} />
        );
    }

    return store ? (
        <Provider store={store}>
            <App />
        </Provider>
    ) : null;
};

export default Bootstrapper;
