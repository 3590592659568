import { isMobile } from 'modules/applicationConfig';

export default () =>
    isMobile()
        ? {
              margin: '7px 0',
              height: 40,
              fontSize: 16,
              width: '100%',
              '&:disabled': {
                  opacity: 0.65,
                  cursor: 'not-allowed',
              },
          }
        : {
              margin: '7px 0',
              height: 20,
              fontSize: 12,
              minWidth: 184,
              '&:disabled': {
                  opacity: 0.65,
                  cursor: 'not-allowed',
              },
          };
