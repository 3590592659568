export enum OzRoute {
    BANKDATEN_KORRIGIEREN = '/bankdatenKorrigieren',
    BEREITS_ZUGELASSEN = '/bereitsZugelassen',
    DATENEINGABE = '/dateneingabe',
    DEFAULT = '/app',
    FAHRZEUGDATEN_KORRIGIEREN = '/fahrzeugdatenKorrigieren',
    HALTERDATEN_KORRIGIEREN = '/halterdatenKorrigieren',
    HUB = '/hub',
    UEBERSICHT = '/uebersicht',
}
