import { SESSION } from '@oz/shared/out/constants/getParameterNames';
import { SM_KEY } from '@oz/shared/out/constants/webapp';
import validateSession from '@oz/shared/out/validators/validateSession';
import { sessionStorageHelper } from 'modules/storage';

export const getSessionMatcher = (): string => {
    return sessionStorageHelper.getItem(SM_KEY) || '';
};

export const getSession = () => {
    const search = new URLSearchParams(window.location.search);
    const session = search.get(SESSION) || '';
    return validateSession(session) ? session : undefined;
};
