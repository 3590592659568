export enum SupportedStorage {
    LOCAL_SESSION_STORAGE = 'LOCAL_SESSION_STORAGE',
    SESSION_STORAGE = 'SESSION_STORAGE',
    LOCAL_STORAGE = 'LOCAL_STORAGE',
    NONE = 'NONE',
}

export enum StorageType {
    SESSION_STORAGE = 'sessionStorage',
    LOCAL_STORAGE = 'localStorage',
}

function Storage(type: StorageType) {
    let storage = {} as Storage;

    try {
        if (type === StorageType.SESSION_STORAGE) {
            storage = window.sessionStorage;
        } else {
            storage = window.localStorage;
        }
    } catch (e) {
        // do Nothing
    }

    return {
        setItem: (key: string, value: string): void => {
            try {
                storage.setItem(key, value);
            } catch (e) {
                // do nothing
            }
        },

        getItem: (key: string, notDefined: string | undefined = undefined): string | undefined => {
            return storage.getItem(key) || notDefined; // storage.getItem returns null for undefined keys
        },

        removeItem: (key: string): void => {
            try {
                storage.removeItem(key);
            } catch (e) {
                // do nothing
            }
        },

        clear: (): void => {
            try {
                storage.clear();
            } catch (e) {
                // do nothing
            }
        },

        test: (): boolean => {
            try {
                storage.setItem('test', 'test');
                storage.removeItem('test');
                return true;
            } catch (e) {
                return false;
            }
        },
    };
}

export const sessionStorageHelper = Storage(StorageType.SESSION_STORAGE);
export const localStorageHelper = Storage(StorageType.LOCAL_STORAGE);

export const localStorageSupported = (): boolean => localStorageHelper.test();
export const sessionStorageSupported = (): boolean => sessionStorageHelper.test();

export const storageTypeSupported = (): SupportedStorage => {
    if (sessionStorageSupported() && localStorageSupported()) {
        return SupportedStorage.LOCAL_SESSION_STORAGE;
    }
    if (sessionStorageSupported()) {
        return SupportedStorage.SESSION_STORAGE;
    }
    if (localStorageSupported()) {
        return SupportedStorage.LOCAL_STORAGE;
    }
    return SupportedStorage.NONE;
};
