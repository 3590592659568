import throttle from 'lodash/throttle';

import { AjaxEndpoint } from '@oz/shared/out/enums/AjaxEndpoint';
import { LogType } from '@oz/shared/out/modules/logging/logger';
import { makeRequestFunction } from 'modules/ajax';
import { getSessionId } from 'modules/applicationConfig';

interface Params {
    level: LogType;
    message?: string;
    additionalData?: Record<string, unknown>;
}

export const logRequestInternal = async ({ level, message, additionalData }: Params): Promise<void> => {
    const logReq = makeRequestFunction<unknown, unknown>(AjaxEndpoint.LOGS, false);
    try {
        await logReq({
            level,
            message,
            additionalData: { ...additionalData, userAgent: window?.navigator?.userAgent },
        });
    } catch (e) {
        // do nothing
    }
};

export const logRequest = throttle(logRequestInternal, 1000, { leading: false });

export const globalErrorLog = (): void => {
    window.addEventListener('unhandledrejection', event => {
        const session = getSessionId();

        logRequest({
            level: LogType.ERROR,
            message: event.reason.message.toString(),
            additionalData: { stack: event.reason.stack.toString(), session },
        });
    });
};
