export const styles = {
    button: {
        width: 270,
        margin: '25px auto',
        padding: '5px 45px',
        color: '#fff',
    },
    wrapper: {
        padding: 25,
        maxWidth: 350,
    },
};
